export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_CLEAR_DATA = 'LOGIN_CLEAR_DATA'

export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS'
export const REQUEST_RESET_PASSWORD_FAIL = 'REQUEST_RESET_PASSWORD_FAIL'
export const REQUEST_RESET_PASSWORD_CLEAR_DATA = 'REQUEST_RESET_PASSWORD_CLEAR_DATA'

export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL'
export const SET_PASSWORD_CLEAR_DATA = 'SET_PASSWORD_CLEAR_DATA'
