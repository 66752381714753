const FullLayout = ({ children }) => {
  return (
    <div>
      {children}
      Full Layout
    </div>
  )
}

export default FullLayout
